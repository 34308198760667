import React, { useEffect, useState } from 'react'
import { useApiGetRecords, useSaveIdentifierBatch, useApiGetAccount } from '../hooks'
import { IdentifierRowData, Field } from '../types'
import IdentifierRow from './IdentifierRow'
import { useLocation, useHistory } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import { useSessionAccount } from '@papertrail/web3-session'
import ConfirmDialog from './ConfirmDialog'

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  ButtonPrimary,
  Box,
  Stack,
  Loading,
  ButtonSecondary
} from '@papertrail/styleguide'

export default function IdentifierDialog() {
  const location = useLocation()
  const history = useHistory()
  const account = useSessionAccount()
  const accountId = account ? account.id : null
  const params = new URLSearchParams(location.search)
  const recordIds = params.get('records')
  const [accountState, loadAccount] = useApiGetAccount(accountId)
  const [recordsState, loadRecords] = useApiGetRecords(accountId, recordIds)
  const [identifiersTable, setIdentifiersTable] = useState([])

  const [fields, setFields] = useState([])
  const [saveIdentifiersState, saveIdentifiers] = useSaveIdentifierBatch(accountId)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  useEffect(() => {
    if (saveIdentifiersState.isLoaded) {
      dispatchEvent(new CustomEvent('identifiersSaved'))
    }
  }, [saveIdentifiersState])

  useEffect(() => {
    if (accountId && recordIds) {
      loadRecords()
      loadAccount({ include: 'blueprint' })
    }
  }, [recordIds, accountId])

  function getFields(blueprint) {
    const localFields = []

    for (const section of blueprint.sections.data) {
      for (const field of section.fields.data) {
        if (field.type === 'identifier' || field.type === 'rfid' || field.type === 'barcode') {
          localFields.push(field)
        }
      }
    }

    return localFields
  }

  useEffect(() => {
    if (accountState.isLoaded) {
      setFields(getFields(accountState.data.data.blueprint.data))
    }
  }, [accountState])

  function getImageUrl(record) {
    if (record.uploads && record.uploads.data.length) {
      for (const upload of record.uploads.data) {
        if (upload.is_image && upload.default) {
          return `/api${upload.view}`
        }
      }
    }

    return null
  }

  useEffect(() => {
    if (recordsState.isLoaded && accountState.isLoaded && fields.length) {
      const identifierRows = []

      for (const record of recordsState.data) {
        const identifierRow = {
          recordId: record.id,
          recordName: record.name,
          recordImageUrl: getImageUrl(record),
          fields: []
        }

        for (const field of fields) {
          const recordField = (record as any).fields.data.find((f) => f.key === field.key)
          const value = recordField ? recordField.value : ''
          identifierRow.fields.push({ key: field.key, value, label: field.label })
        }

        identifierRows.push(identifierRow)
      }

      identifierRows.sort((a, b) => {
        return recordIds.indexOf(a.recordId) - recordIds.indexOf(b.recordId)
      })

      setIdentifiersTable(identifierRows)
    }
  }, [recordsState, accountState, fields])

  function fieldChanged(recordId, field, value) {
    const newIds = identifiersTable.map((identifier: IdentifierRowData) => {
      if (recordId === identifier.recordId) {
        return {
          ...identifier,
          fields: identifier.fields.map((f) => {
            if (f.key === field) {
              return {
                ...f,
                value
              }
            } else {
              return f
            }
          })
        }
      } else {
        return identifier
      }
    })

    setIdentifiersTable(newIds)
  }

  function onSave() {
    saveIdentifiers(identifiersTable, fields)
    onCancel()
  }

  function pasteAll() {
    navigator.clipboard.readText().then((text) => {
      if (identifiersTable.length && fields.length) {
        paste(text)
      }
    })
  }

  interface Record {
    recordId: string
    fields: { key: string; value: string }[]
  }

  function searchRecordByRecordId(
    records: Record[],
    targetRecordId: string
  ): { record: Record | undefined; index: number } {
    const index = records.findIndex((record) => record.recordId === targetRecordId)
    const foundRecord = index !== -1 ? records[index] : undefined
    return { record: foundRecord, index }
  }

  function paste(text, recordId?, fieldKey?) {
    // Clone the identifiers table
    const clonedIdentifiersTable = cloneDeep(identifiersTable)

    // Split the pasted text into rows and columns
    const pastedData = text.split('\n').map((line) => line.split('\t'))

    // If the "Paste" button has been clicked
    if (!recordId) {
      for (let i = 0; i < pastedData.length; i++) {
        const identifier = clonedIdentifiersTable[i]

        if (identifier) {
          for (let j = 0; j < pastedData[0].length; j++) {
            const cell = pastedData[i][j]

            if (identifier.fields[j]) {
              identifier.fields[j].value = cell
            }
          }
        }
      }

      setIdentifiersTable(clonedIdentifiersTable)
      return
    }

    const { record, index } = searchRecordByRecordId(clonedIdentifiersTable, recordId)

    // Paste single value into cell
    if (pastedData.length === 1 && pastedData[0].length === 1) {
      const fields = record.fields
      const fieldIndex = fields.findIndex((field) => field.key === fieldKey)
      fields[fieldIndex].value = text
      clonedIdentifiersTable[index].fields = fields
    }

    // Paste single or multiple rows
    if (pastedData.length >= 1 && pastedData[0].length === record.fields.length) {
      // Stops the index going out of bounds and throwing an error
      const indexToStop = Math.min(index + pastedData.length, clonedIdentifiersTable.length)

      let newRowValuesIndex = 0

      for (let recordIndex = index; recordIndex < indexToStop; recordIndex++) {
        const selectedRecord = clonedIdentifiersTable[recordIndex]

        for (let i = 0; i < selectedRecord.fields.length; i++) {
          if (newRowValuesIndex < pastedData.length) {
            selectedRecord.fields[i].value = pastedData[newRowValuesIndex][i]
          }
        }

        newRowValuesIndex++
      }
    }
    // If the pasted data is a single column across multiple rows
    if (pastedData.length > 1 && pastedData.every((subArray) => subArray.length === 1)) {
      // index = currentIndex
      const indexToStop = Math.min(index + pastedData.length, clonedIdentifiersTable.length)

      let currentRowIndex = index

      for (let pastedDataIndex = 0; pastedDataIndex < pastedData.length; pastedDataIndex++) {
        if (currentRowIndex < indexToStop) {
          const pastedDataValue = pastedData[pastedDataIndex][0]

          const selectedRecord = clonedIdentifiersTable[currentRowIndex]
          const fields = selectedRecord.fields
          const fieldIndex = fields.findIndex((field) => field.key === fieldKey)
          fields[fieldIndex].value = pastedDataValue
          clonedIdentifiersTable[currentRowIndex].fields = fields

          currentRowIndex++
        }
      }
    }

    setIdentifiersTable(clonedIdentifiersTable)
  }

  const savingText = saveIdentifiersState.isLoading ? 'Saving...' : 'Save'

  function onCancel() {
    const params = location.search.substring(0, location.search.indexOf('&records='))
    history.push(`${location.pathname}${params}`)
  }

  function clearForm() {
    setConfirmDialogOpen(true)
  }

  function clearConfirm() {
    setIdentifiersTable(
      identifiersTable.map((identifier: IdentifierRowData) => {
        return {
          ...identifier,
          fields: identifier.fields.map((f) => ({
            ...f,
            value: ''
          }))
        }
      })
    )

    setConfirmDialogOpen(false)
  }

  function clearCancel() {
    setConfirmDialogOpen(false)
  }

  const loading = accountState.isLoading || recordsState.isLoading
  const recordNumber = recordIds ? recordIds.split(',').length : 0

  if (!recordIds) {
    return null
  } else {
    return (
      <Dialog open={true} maxWidth="xl">
        <DialogTitle>
          Set {recordNumber} {recordNumber === 1 ? 'identifiers' : 'identifiers'}
        </DialogTitle>
        <DialogContent>
          {loading && (
            <Box sx={{ minHeight: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Stack>
                <Loading />
                Loading...
              </Stack>
            </Box>
          )}

          {!loading && (
            <Box sx={{ minHeight: '100px', width: 'auto', minWidth: '1000px' }}>
              <Box
                sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginBottom: 1, gap: 1 }}>
                <ButtonSecondary onClick={pasteAll}>Paste</ButtonSecondary>
                <ButtonSecondary onClick={clearForm}>Clear</ButtonSecondary>
              </Box>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: `1fr repeat(${fields.length},1fr)`,
                  rowGap: '15px',
                  columnGap: '15px',
                  minHeight: '100px'
                }}>
                <Box>
                  <b>Record name</b>
                </Box>

                {fields.map((f: Field) => (
                  <Box key={`header-${f.key}`}>
                    <b>{f.label}</b>
                  </Box>
                ))}

                {identifiersTable &&
                  identifiersTable.map((identifier: IdentifierRowData) => (
                    <IdentifierRow
                      key={identifier.recordId}
                      identifier={identifier}
                      fieldChanged={fieldChanged}
                      pasteMultiple={paste}
                    />
                  ))}
              </div>

              {confirmDialogOpen && <ConfirmDialog onCancel={clearCancel} onConfirm={clearConfirm} />}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Stack gap={1} direction="row">
            <ButtonSecondary onClick={onCancel}>Cancel</ButtonSecondary>
            <ButtonPrimary onClick={onSave} disabled={saveIdentifiersState.isLoading || saveIdentifiersState.isError}>
              {savingText}
            </ButtonPrimary>
          </Stack>
        </DialogActions>
      </Dialog>
    )
  }
}
